import React, { createContext, useEffect, useState,useRef } from 'react';
const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [Data, setData] = useState("");
  const [cartdata,setcartdata] = useState([]) // Initialize your global data here
  const [cartcount,setcartcount] = useState('')
 
  const didMountRef = useRef(true);
 
 
  return (
    <DataContext.Provider value={
      {
        cartcount, setcartcount,
    }
      }>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;