
import React, { useEffect, useRef, useState } from "react";
import constant from "../../Components/Services/constant";
import { ApiService } from "../../Components/Services/apiservices";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper/modules";
import Skeleton from 'react-loading-skeleton'
const Shopoccasion = () => {
  const [resCollectionData, setResCollectionData] = useState([]);
  const [collectionImagePath, setCollectionImagePath] = useState('');
  const [loading, setLoading] = useState(true)
  const skeletonArray = Array.from({ length: 4 });
  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
      getCollectionData()
    }
    didMountRef.current = false;
  }, []);
  const getCollectionData = () => {
    ApiService.fetchData("featured-collection-category").then((res) => {
      if (res.status == "success") {
        setResCollectionData(res.resCategory);
        setCollectionImagePath(res.category_image_path);
        setLoading(false)
      }else{
        setLoading(false)
      }
    });
  };
  return (<>
    {
      resCollectionData.length > 0 ?
        <section className="sec-pad">
          <div className="container">
            <div className="section-title d-flex align-items-center justify-content-center mb-5">
              <h2 className="mb-0">Shop By Occasion</h2>
            </div>
            <Swiper
              spaceBetween={15}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              breakpoints={{
                0: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 3,
                },
                992: {
                  slidesPerView: 4,
                },
              }}
            >
              {resCollectionData.map((value, index) => {
                return (
                  <SwiperSlide key={index}>
                    <a href={"/collection/category/" + value.cat_slug} className="catbox item" key={index}>
                      <div className="catbox-media">
                        <img
                          src={
                            value.cat_image != null
                              ? collectionImagePath + value.cat_image
                              : constant.DEFAULT_IMAGE
                          }
                          alt={value.cat_name}
                        />
                      </div>
                      <div className="catbox-title">{value.cat_name}</div>
                    </a>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </section>
        : null}
  </>)
}

export default Shopoccasion