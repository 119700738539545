import React, { useEffect, useState, useRef } from 'react';
import { ColorRing,Triangle, Vortex,} from  'react-loader-spinner'
function SpinnerLoader() {
  return (
    <>
          <div className='loadergif'>
      <img
              src="/img/logonew.gif" 
              alt="Loading..."
          
              width="100px"
            />
        </div>
      {/* <div className='parentDisable' width="100%">
          <div className='overlay-box'>
          <Vortex
            visible={true}
            height="80"
            width="80"
            ariaLabel="vortex-loading"
            wrapperStyle={{}}
            wrapperClass="vortex-wrapper"
            colors={['#bc7f41', '#bc7f41', '#bc7f41', '#bc7f41', '#bc7f41', '#bc7f41']}
          />
   
          </div>
        </div> */}
                   {/* <Triangle
                height="80"
                width="80"
                color="#bc7f41"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              /> */}
            {/* <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={['#ed1c24', '#ed1c24', '#ed1c24', '#ed1c24', '#ed1c24']}
            /> */}
    </>
  );
}
export default SpinnerLoader;
