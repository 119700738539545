import React, { useEffect, useState, useRef } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import Form from 'react-bootstrap/Form';
import Accordion from "react-bootstrap/Accordion";
import Footer from "../../Components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SpinnerLoader from "../../Components/Elements/spinner_loader";
import { ApiService } from "../../Components/Services/apiservices";
import CartHeader from "../../Components/CartHeader";
import sessionCartData from "../../Components/Elements/cart_session_data";
import { Link, useNavigate, useLocation } from "react-router-dom";
import constant from "../../Components/Services/constant";
import multiCurrency from "../../Components/Elements/multi_currrency";
import numeral from "numeral";
import useRazorpay from "react-razorpay";

function CartCheckout() {
  const didMountRef = useRef(true);
  const Navigate = useNavigate();
  const [Razorpay] = useRazorpay();
  const dataArray = sessionCartData();
  const parsedAddressSession = dataArray[0];
  const [parsedCartSession, setparsedCartSession] = useState(dataArray[1]);
  const parsedCouponSession = dataArray[2];
  const [cartSummary, setcartSummary] = useState(dataArray[3]);
  const [settingData, setSettingData] = useState({});
  const [shippingRateData, setShippingRateData] = useState({});
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState(""); 
  const [paymentType, setPaymentType] = useState("1"); 
  const [shippingCharge, setShippingCharge] = useState(0); 
  const [shippingData, setShippingData] = useState({}); 
  const [userData, setUserData] = useState("");

  useEffect(() => {
    if (didMountRef.current) {
      getuserData()
      getSettingsData()
      if (sessionStorage.getItem("USER_TOKEN")) {
        cartSessionData();
      }
      //calculateShippingAmount(paymentType)
    }
    didMountRef.current = false;
  }, []);
  const cartSessionData = () => {
    const dataString = {
      coupon_session: sessionStorage.getItem("COUPON_SESSION"),
    };
    ApiService.postData("cartSessionData", dataString).then((res) => {
      if (res.data.status === "success") {
        setparsedCartSession(res.data.resCartData)
        setcartSummary(res.data.cartSummary)
      }
    });
  }
  const getSettingsData = () => {
    ApiService.fetchData("settings").then((res) => {
      if (res.status === "success") {
        setSettingData(res.sitesettings);
        setShippingRateData(res.shippingRateData);
      }
    });
  };  
  
  const calculateShippingAmount = (paymentType) => {
    const dataString = {
      itemtotal: cartSummary.total_amount,
      ua_id: parsedAddressSession.ua_id,
      cart_data: parsedCartSession,
      payment_type:paymentType,
      payment_type_admin:settingData.admin_cod_status
    };

    ApiService.postData("calculate-shipping-amount", dataString).then((res) => {
      if (res.status === "success") {
        setShippingCharge(res.shipping_amount);
        setShippingData(res.shipping_data)
        setSpinnerLoading(false);
      }
    });
  };
  const selectpaymentMode = (mode) => {
    setPaymentMethod(mode)
    if(mode === 'COD'){
      setPaymentType('1')
      setSpinnerLoading(true);
      calculateShippingAmount('1')
    }else{
      setPaymentType('0')
      setSpinnerLoading(true);
      calculateShippingAmount('0')
    }
  };

  const getuserData = () => {
    ApiService.fetchData("get-user-data").then((res) => {
      if (res.status == "success") {
        setUserData(res.rowUserData)
         setSpinnerLoading(false);
      } else {
        sessionStorage.removeItem("USER_TOKEN");
        setSpinnerLoading(false);
        Navigate("/");
      }
    });
  };

  const choutOutProccess = () => {
    const MultiCurrencySession = sessionStorage.getItem("MULTI_CURRENCY");
    const parsedMultiCurrencySession = MultiCurrencySession ? JSON.parse(MultiCurrencySession) : {};
    if(paymentMethod === ''){
      toast.error('Please select Payment Method')
      return;
    }

    if(paymentMethod !== 'COD'){
      /* toast.error('Cash on Delivery is available')
      return; */
      if(settingData.admin_payment_active===1){
         razoprpay()
      }else{
        // ccavenue()
       // razoprpay()
      }
    }else{
      const dataString = {
        cartSummary: cartSummary,
        parsedAddressSession: parsedAddressSession,
        parsedCartSession: parsedCartSession,
        parsedCouponSession: parsedCouponSession,
        paymentMethod: paymentMethod,
        shippingCharge: shippingCharge / parsedMultiCurrencySession.cr_rate,
        currencyData: parsedMultiCurrencySession,
        shippingData:shippingData,
        textarea:textarea
      };
      setSpinnerLoading(true)
      ApiService.postData("makecodorder", dataString).then((res) => {
        if (res.status === "success") {
          sessionStorage.removeItem("CART_SESSION")
          sessionStorage.removeItem("ADDRESS_SESSION")
          sessionStorage.removeItem("COUPON_SESSION")
          //sessionStorage.setItem("TRANS_ID", res.order_number);
          Navigate('/thankyou/'+res.order_number)
        } else{
          setSpinnerLoading(false)
        }
      });
    }

  };

  const ccavenue = () => {
    const MultiCurrencySession = sessionStorage.getItem("MULTI_CURRENCY");
    const parsedMultiCurrencySession = MultiCurrencySession ? JSON.parse(MultiCurrencySession) : {};
    const dataString = {
      cartSummary: cartSummary,
      parsedAddressSession: parsedAddressSession,
      parsedCartSession: parsedCartSession,
      parsedCouponSession: parsedCouponSession,
      paymentMethod: paymentMethod,
      shippingCharge: numeral(shippingCharge / parsedMultiCurrencySession.cr_rate).format('0,0'),
      currencyData: parsedMultiCurrencySession,
      shippingData:shippingData,
      textarea:textarea
    };
    setSpinnerLoading(true)
    ApiService.postData("initiateCCPayment", dataString).then((res) => {
      if (res.status === "success") {
        //window.location.href = constant.BASE_URL+"csadmin/ccpayment/"+res.rowTempTransData.temp_trans_order_id
        // window.location.href = res.production_url;
        setSpinnerLoading(false)
      } else{
        setSpinnerLoading(false)
      }
    });
  } 

  const razoprpay = () =>{
    const dataString = {
      amount: cartSummary.total_amount,
      paymentMethod: paymentMethod,
      shippingCharge: shippingCharge,
      couponAmount: parsedCouponSession.discount_amount,
      cartSummary: cartSummary,
      parsedAddressSession: parsedAddressSession,
      parsedCartSession: parsedCartSession,
      parsedCouponSession: parsedCouponSession,
      shippingData: shippingData,
      textarea: textarea
    };
    setSpinnerLoading(true)
    ApiService.postData("createtemporder", dataString).then((res) => {
      if (res.status === "success") {
        setSpinnerLoading(false)
        const orderOptions = {
          key: res.rowAdminData.admin_razorpay_key,
          amount: res.row_temp_trans.total_amount * 100,
          currency: "INR",
          name: "Swadeshi Pitara",
          description: "Order Amount",
          image: res.logopath + res.rowAdminData.razorpay_logo,
          order_id: res.row_temp_trans.temp_razorpay_order_id,
          handler: (response) => {
            RazorPayProcess(response, res.row_temp_trans.temp_trans_order_id);
          },
          prefill: {
            name: `${userData.user_fname} ${userData.user_lname}`,
            email: userData.user_email,
            contact: userData.user_mobile,
          },
          theme: {
            color: "#674387",
          },
        };
        const rzpay = new Razorpay(orderOptions);
        rzpay.open();
      } else {
        setSpinnerLoading(false);
      }
    });
  } 

  const RazorPayProcess = (response, orderId) => {
    const MultiCurrencySession = sessionStorage.getItem("MULTI_CURRENCY");
    const parsedMultiCurrencySession = MultiCurrencySession ? JSON.parse(MultiCurrencySession) : {};
    const paymentData = {
      orderId: orderId,
      paymentId: response.razorpay_payment_id,
      cartSummary: cartSummary,
      parsedAddressSession: parsedAddressSession,
      parsedCartSession: parsedCartSession,
      parsedCouponSession: parsedCouponSession,
      paymentMethod: paymentMethod,
      shippingCharge: numeral(shippingCharge / parsedMultiCurrencySession.cr_rate).format('0,0'),
      currencyData: parsedMultiCurrencySession,
      shippingData: shippingData,
      textarea: textarea
    };
    setSpinnerLoading(true)
    ApiService.postData("paymentprocessing", paymentData).then((result) => {
      if (result.status === "success") {
        setSpinnerLoading(false);
        Navigate('/thankyou/' + result.order_number)
      } else {
        setSpinnerLoading(false);
        toast.error("Payment processing failed. Please try again.");
      }
    });
  };


  const [textarea, setTextarea] = useState('');

  const handleChangeTextarea = (event) => {
    setTextarea(event.target.value);
  };
 
  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
      />

      <CartHeader />
      {spinnerLoading && <SpinnerLoader />}
      <BrowserView>
        <main>
          <div className="page-content mt-5 mb-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="address-checkout mb-3">
                    <a href="/address" className="change-address">
                      Change
                    </a>
                    <h6>Shipping and Billing Address</h6>
                    <p style={{ marginBottom: "3px", fontWeight: "600" }}>
                      {parsedAddressSession.ua_name} (
                      {parsedAddressSession.ua_address_type == "Other"
                        ? parsedAddressSession.ua_address_type_other
                        : parsedAddressSession.ua_address_type}
                      )
                    </p>
                    <p className="address-full" style={{ marginBottom: "3px" }}>
                      Mobile No: {parsedAddressSession.ua_mobile}
                    </p>
                    <div className="address-full">
                      {parsedAddressSession.ua_house_no}, {parsedAddressSession.ua_area},
                      {parsedAddressSession.ua_city_name}, {parsedAddressSession.ua_state_name}
                      {parsedAddressSession.ua_pincode}
                    </div>
                  </div>
                  <Accordion defaultActiveKey={['0','2']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="checkout-accord">
                      <Accordion.Header>
                        <h6 className="mb-0 tx-14">Order Summary</h6>
                      </Accordion.Header>
                      <Accordion.Body>
                        {parsedCartSession.map((value, index) => {
                          return (
                            <div className="cartsec mt-0" key={index}>
                              <div className="row g-3">
                                <div className="col-lg-2 col-3">
                                  <div className="cartsec-media">
                                    <img src={value.product_image} />
                                  </div>
                                </div>
                                <div className="col-lg-9 col-9">
                                  <h6 className="cartsec-name">
                                    <a href="#" className="mycartbox-title">
                                      {value.product_name}
                                    </a>
                                  </h6>
                                  <div className="cartsec-price">
                                    <div className="price-new me-2">
                                      
                                      {multiCurrency(
                                        value.product_selling_price
                                      )}
                                    </div>
                                    {value.product_discount >0 ?
                                    <div className="price-old">
                                      
                                      {multiCurrency(value.product_price)}
                                    </div>:null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className="checkout-accord">
                      <Accordion.Header>
                        <h6 className="mb-0 tx-14">Order Note</h6>
                      </Accordion.Header>
                      <Accordion.Body>
                         <div className="order-notetextarea">
                            <textarea name="textarea" className="form-control" placeholder="How can we help you?" value={textarea}
                            onChange={handleChangeTextarea}/>
                         </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey="2"
                      className="checkout-accord"
                    >
                      <Accordion.Header>
                        <h6 className="mb-0 tx-14">Payment Method</h6>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="payment-option-list">
                          <ul>
                            {settingData.admin_cod_status === 1?
                            <li onClick={(e) => selectpaymentMode('COD')}>
                              <img src="/img/delivery.png" />
                              Cash On Delivery
                              <Form.Check
                                className="ml-auto"
                                name="payment_method"
                                type="radio"
                                value="COD"
                                checked={paymentMethod === 'COD' ? true : false}
                              /> 
                            </li>
                            :null}
                            <li onClick={(e) => selectpaymentMode('credit-debit')}>
                              <img src="/img/creditcard.png" />
                              Credit/Debit Card
                              <Form.Check
                                className="ml-auto"
                                name="payment_method"
                                type="radio"
                                value="credit-debit"
                                checked={paymentMethod === 'credit-debit' ? true : false}
                              /> 
                            </li>
                            <li onClick={(e) => selectpaymentMode('upi')}>
                              <img src="/img/phonepe.png" />
                              PhonePe/Gogle Pay/BHIM UPI
                              <Form.Check
                                className="ml-auto"
                                name="payment_method"
                                type="radio"
                                value="upi"
                                checked={paymentMethod === 'upi' ? true : false}
                              /> 
                            </li>
                            <li onClick={(e) => selectpaymentMode('wallet')}>
                              <img src="/img/paytm.png" />
                              Paytm/Payzapp/Wallets
                              <Form.Check
                                className="ml-auto"
                                name="payment_method"
                                type="radio"
                                value="wallet"
                                checked={paymentMethod === 'wallet' ? true : false}
                              />  
                            </li>
                            <li  onClick={(e) => selectpaymentMode('netbanking')}>
                              <img src="/img/netbanking.png" />
                              Netbanking
                              <Form.Check
                                className="ml-auto"
                                name="payment_method"
                                type="radio"
                                value="netbanking"
                                checked={paymentMethod === 'netbanking' ? true : false}
                               
                              />   
                            </li>
                          </ul>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="col-lg-4">
                  <div className="panel p-4 mb-3">
                    <div className="panel-header">Price Details</div>
                    <div className="pcb-list mb-4">
                      <ul>
                        <li>
                          Item Total
                          <span className="ml-auto">
                            {multiCurrency(cartSummary.itemTotal)}
                          </span>
                        </li>
                        <li>
                          Discount
                          <span className="ml-auto tx-green">
                            -{multiCurrency(cartSummary.discount)}
                          </span>
                        </li>
                        <li>
                          Coupon Discount
                          <span className="ml-auto tx-green">
                            -
                            {multiCurrency(parsedCouponSession.discount_amount)}
                          </span>
                        </li>
                        <li>
                          Shipping Charge
                          <span className="ml-auto" id="shippingAmount">
                            {multiCurrency(shippingCharge)}
                          </span>
                        </li>
                        {settingData.estimated_delivery !== null && (
                          <li>
                            Estimated Delivery Time:
                            <span style={{ marginLeft: '10px' }}>
                              {settingData.estimated_delivery} {settingData.estimated_delivery === 1 ? 'day' : 'days'}
                            </span>
                          </li>
                        )}
                      </ul>
                    </div>
                    <hr />
                    <div className="pcb-list-second">
                      <ul>
                        <li>
                          Total Amount
                          <span className="ml-auto" id="finalTotal">
                            {multiCurrency(cartSummary.total_amount + shippingCharge)}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <hr />
                    <p className="text-center mt-20">
                      We Accepted all Major Cards
                    </p>
                    <div className="cardlist">
                      <i className="fab fa-cc-paypal"></i>
                      <i className="fab fa-cc-mastercard"></i>
                      <i className="fab fa-cc-discover"></i>
                      <i className="fab fa-cc-visa"></i>
                    </div>
                  </div>
                  <a
                    href="javascript:void(0);"
                    className="btn btn-primary btn-block btn-large"
                    onClick={choutOutProccess}
                  >
                    Proceed to Checkout
                  </a>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </BrowserView>
      <MobileView>
        <div className="address-checkout">
          <a
            href="/address"
            className="change-address"
          >
            Change
          </a>
          <h6>Shipping and Billing Address</h6>
          <p style={{ marginBottom: "3px", fontWeight: "600" }}>
            {parsedAddressSession.ua_name} (
            {parsedAddressSession.ua_address_type == "Other"
              ? parsedAddressSession.ua_address_type_other
              : parsedAddressSession.ua_address_type}
            )
          </p>
          <p className="address-full" style={{ marginBottom: "3px" }}>
            Mobile No: {parsedAddressSession.ua_mobile}
          </p>
          <div className="address-full">
            {parsedAddressSession.ua_house_no}, {parsedAddressSession.ua_area},
            {parsedAddressSession.ua_city_name}, {parsedAddressSession.ua_state_name}
            {parsedAddressSession.ua_pincode}
          </div>
        </div>
        <Accordion defaultActiveKey={['2']} alwaysOpen>
          <Accordion.Item eventKey="0" className="checkout-accord">
            <Accordion.Header>
              
              <h6 className="mb-0 tx-14">Order Summary</h6>
            </Accordion.Header>
            <Accordion.Body>
              {parsedCartSession.map((value, index) => { 
                return (
                  <div className="cartsec mt-0" key={index}>
                    <div className="row g-3">
                      <div className="col-lg-2 col-3">
                        <div className="cartsec-media">
                          <img src={value.product_image} />
                        </div>
                      </div>
                      <div className="col-lg-9 col-9">
                        <h6 className="cartsec-name">
                          <a href="#" className="mycartbox-title">
                            {value.product_name}
                          </a>
                        </h6>
                        <div className="cartsec-price">
                          <div className="price-new me-2">
                            
                            {multiCurrency(value.product_selling_price)}
                          </div>
                          <div className="price-old">
                            {multiCurrency(value.product_price)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className="checkout-accord">
            <Accordion.Header>
              <h6 className="mb-0 tx-14">Order Note</h6>
            </Accordion.Header>
            <Accordion.Body>
                <div className="order-notetextarea">
                  <textarea name="textarea" className="form-control" placeholder="How can we help you?" value={textarea}
                  onChange={handleChangeTextarea}/>
                </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className="checkout-accord">
            <Accordion.Header>
              <h6 className="mb-0 tx-14">Payment Method</h6>
            </Accordion.Header>
            <Accordion.Body>
              <div className="payment-option-list">
              <ul>
                {settingData.admin_cod_status === 1?
                <li>
                  <img src="/img/delivery.png" />
                  Cash On Delivery
                  <Form.Check
                    className="ml-auto"
                    name="payment_method"
                    type="radio"
                    value="COD"
                    onChange={(e)=>selectpaymentMode('COD')}
                  /> 
                </li>
                :null}
                <li>
                  <img src="/img/creditcard.png" />
                  Credit/Debit Card
                  <Form.Check
                    className="ml-auto"
                    name="payment_method"
                    type="radio"
                    value="credit-debit"
                    onChange={(e)=>selectpaymentMode('credit-debit')}
                  /> 
                </li>
                <li>
                  <img src="/img/phonepe.png" />
                  PhonePe/Gogle Pay/BHIM UPI
                  <Form.Check
                    className="ml-auto"
                    name="payment_method"
                    type="radio"
                    value="upi"
                    onChange={(e)=>selectpaymentMode('upi')}
                  /> 
                </li>
                <li>
                  <img src="/img/paytm.png" />
                  Paytm/Payzapp/Wallets
                  <Form.Check
                    className="ml-auto"
                    name="payment_method"
                    type="radio"
                    value="wallet"
                    onChange={(e)=>selectpaymentMode('wallet')}
                  />  
                </li>
                <li>
                  <img src="/img/netbanking.png" />
                  Netbanking
                  <Form.Check
                    className="ml-auto"
                    name="payment_method"
                    type="radio"
                    value="netbanking"
                    onChange={(e)=>selectpaymentMode('netbanking')}
                  />   
                </li>
              </ul>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <div className="panel checkoutlist">
          <div className="panel-header">Price Details</div>
          <div className="pcb-list mb-4">
            <ul>
              <li>
                Item Total
                <span className="ml-auto">
                  {multiCurrency(cartSummary.itemTotal)}
                </span>
              </li>
              <li>
                Discount
                <span className="ml-auto tx-green">
                  -{multiCurrency(cartSummary.discount)}
                </span>
              </li>
              <li>
                Coupon Discount
                <span className="ml-auto tx-green">
                  -{multiCurrency(parsedCouponSession.discount_amount)}
                </span>
              </li>
              <li>
                Shipping Charge
                <span className="ml-auto" id="shippingAmount">
                  {multiCurrency(shippingCharge)}
                </span>
              </li>
              {settingData.estimated_delivery !== null && (
                          <li>
                            Estimated Delivery Time:
                            <span style={{ marginLeft: '10px' }}>
                              {settingData.estimated_delivery} {settingData.estimated_delivery === 1 ? 'day' : 'days'}
                            </span>
                          </li>
                        )}
            </ul>
          </div>
          <hr />
          <div className="pcb-list-second">
            <ul>
              <li>
                Total Amount
                <span className="ml-auto" id="finalTotal">
                  {multiCurrency(cartSummary.total_amount + shippingCharge)}
                </span>
              </li>
            </ul>
          </div>
          <hr />
          <p className="text-center mt-20">We Accepted all Major Cards</p>
          <div className="cardlist">
            <i className="fab fa-cc-paypal"></i>
            <i className="fab fa-cc-mastercard"></i>
            <i className="fab fa-cc-discover"></i>
            <i className="fab fa-cc-visa"></i>
          </div>
        </div>
        <div className="footer-checkout">
          <a
            href="javascript:void(0);"
            className="btn btn-primary btn-block btn-large"
            onClick={choutOutProccess}
          >
            Proceed to Checkout
          </a>
        </div>
      </MobileView>
    </>
  );
}
export default CartCheckout;
