import React, { useEffect, useRef, useState } from "react";
import constant from "../../Components/Services/constant";
import { ApiService } from "../../Components/Services/apiservices";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper/modules";
import Skeleton from 'react-loading-skeleton'
function FeaturedCategories() {
  const didMountRef = useRef(true);
  const [resCategoryData, setResCategoryData] = useState([]);
  const [CategoryImagePath, setCategoryImagePath] = useState('');
  const [loading, setloading] = useState(true)
  useEffect(() => {
    if (didMountRef.current) {
      getCategoryData();
    }
    didMountRef.current = false;
  }, []);
  const getCategoryData = () => {
    ApiService.fetchData("featured-category").then((res) => {
      if (res.status == "success") {
        setResCategoryData(res.resCategory);
        setCategoryImagePath(res.category_image_path);
        setloading(false)
      }else{
        setloading(false)
      }
    });
  };
  const skeletonArray = Array.from({ length: 6 });

  return (
    <>

      {loading ? <section className="sec-pad">
        <div className="container">
          <Swiper
            spaceBetween={10}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            breakpoints={{
              0: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 3,
              },
              992: {
                slidesPerView: 7,
              },
            }}
          >
            {skeletonArray.map((value, index) => {
              return (
                <SwiperSlide key={index}>
                  <a href='' className="catbox item" key={index}>
                    <div className="catbox-media">
                      <Skeleton height={'160px'} style={{ borderRadius: '100%' }}></Skeleton>
                    </div>
                    <div className="catbox-title"> <Skeleton></Skeleton></div>
                  </a>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>
        :
        resCategoryData.length > 0 ?
          <section className="sec-pad">
            <div className="container">
              <Swiper
                spaceBetween={10}
                loop={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                breakpoints={{
                  0: {
                    slidesPerView: 3,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  992: {
                    slidesPerView: 7,
                  },
                }}
              >
                {resCategoryData.map((value, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <a href={"/collection/category/" + value.cat_slug} className="catbox item" key={index}>
                        <div className="catbox-media">
                          <img
                            src={
                              value.cat_image != null
                                ? CategoryImagePath + value.cat_image
                                : constant.DEFAULT_IMAGE
                            }
                            alt={value.cat_name}
                          />
                        </div>
                        <div className="catbox-title">{value.cat_name}</div>
                      </a>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </section>
          : null
      } 
    </>
  );
}
export default FeaturedCategories;
