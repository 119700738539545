import React, { useEffect, useRef, useState } from "react";
import constant from "../../Components/Services/constant";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { ApiService } from "../Services/apiservices";

import moment from "moment";

function Blogs() {
    const didMountRef = useRef(true);

    const [blogData, setBlogData] = useState([])
    const [blogImagePath, setBlogImagePath] = useState('')

    useEffect(() => {

     if(didMountRef.current){
        getBlogData()


     }
     didMountRef.current = false;
    
    }, [])
    

    const getBlogData = () =>{

        ApiService.fetchData("featured-blogs").then((res) => {
            if (res.status == "success") {
                setBlogData(res.blogsData);
                setBlogImagePath(res.blog_image_path);
 
            }
          });
   

    } 
  const blogsCarouselOptions = {
    loop: true,
    spaceBetween: 15,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      600: {
        slidesPerView: 1,
      },
      1000: {
        slidesPerView: 3,
      },
    },
  };

  return (
    <>
    {blogData != null && blogData.length>0 ?
    <section className="sec-pad pt-0">
    <div className="container">
      <div className="section-title text-center mb-5">
        <div className="subtitle">Latest Articles</div>
        <h2>Latest News & Blogs</h2>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Swiper
            {...blogsCarouselOptions}
            modules={[Autoplay]}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >

{blogData.map((value, index) => {
  return (
            <SwiperSlide>
             <div className="listbog">
            <div className="thumbnail">
            <a href={`/blogs/${value.blog_slug}`}>
              <img src={value.blog_image != null ? blogImagePath + "/" + value.blog_image : constant.DEFAULT_IMAGE} alt={value.blog_image}/>
            </a>
            </div>
            <div className="content">
            <div className="read-more-btn">
            <a className="btn-icon-round" href={`/blogs/${value.blog_slug}`}><i className="d-icon-arrow-right"></i></a>
            </div>
            <h5 className="title"><a href={`/blogs/${value.blog_slug}`}>{value.blog_name}</a></h5>
            <ul className="blog-meta">
            
            <li><a href={`/blogs/${value.blog_slug}`}><i className="fas fa-calendar-alt"></i>{moment(value.created_at).format('MMM D, YYYY')}</a>
                                    </li>
            <li><a href={`/blogs/${value.blog_slug}`}><i className="fas fa-user"></i>Admin</a></li>
                    </ul>
            </div>            
          </div> 
            </SwiperSlide>
);
  })}
          </Swiper>
        </div>
      </div>
    </div>
  </section>
  :""}
  </>
  )
}

export default Blogs